import React, { useContext } from "react";
import {
	BrowserRouter as Router,
	Switch, 
	Route,
	Redirect
} from "react-router-dom";
import { routes } from 'routes';
import { AppContext } from 'state';
import axios from 'axios';

function App() {
	// State
	const { state, dispatch } = useContext(AppContext);	

	// Axios global
	axios.defaults.baseURL = state.api_url ? state.api_url : null;
	axios.defaults.headers.common['Authorization'] = state.token ? 'Bearer ' + state.token : null;

	// Axios interceptors
	axios.interceptors.response.use((response) => {
	    // Do something with response data
	    return response;
	}, (error) => {
		// Check unauthorized error
		if ( error.response && error.response.status === 401 ) {
			dispatch({type: 'setToken', data: ""});
			dispatch({type: 'setUser', data: ""});
		}

		// Default
		return Promise.reject(error);
	});

	// Render
	return (
		<Router basename="/admin">
			{ (!state.user || !state.token) &&
				<Redirect to="/login" />
			}	
			<Switch>
				{routes.map((route, i) => <Route key={i} path={route.path} exact={route.exact ?? false}>{route.component}</Route>)}
			</Switch>	
		</Router>
	);
}

export default App;