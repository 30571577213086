import React, { useState, useEffect } from "react";
import AppLayout from 'layouts/AppLayout';
import { createGlobalStyle } from 'styled-components';
import StatsBox from './StatsBox';
import axios from 'axios';
import { NavLink } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
	.alert-wrapper {
		text-decoration: none !important;
	}
`;

let cancelTokenSource = null;

export default function Dashboard() {
	// State
	const [ alvicAlerts, setAlvicAlerts ] = useState(0);
	const [ dyngasAlerts, setDyngasAlerts ] = useState(0);

	// Did mount
	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Interval
		const ajaxInterval = setInterval(() => {
			getAlvicAlerts();
			getDyngasAlerts();
		}, 5000);
		getAlvicAlerts();
		getDyngasAlerts();

		// Unmount callback
		return function cleanup() {
           	cancelTokenSource.cancel();
           	clearInterval(ajaxInterval);
        }
	}, []);
	
	function getAlvicAlerts() {
  		// Ajax
		axios.get('/alvic-logs/get-alerts', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setAlvicAlerts(response.data.count);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setAlvicAlerts(0);
	  	});
  	};
	
	function getDyngasAlerts() {
  		// Ajax
		axios.get('/dyngas-logs/get-alerts', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setDyngasAlerts(response.data.count);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setDyngasAlerts(0);
	  	});
  	};

	return (
		<AppLayout>
			<GlobalStyle />
			<div className="col-md-12 mb-4 text-center">
				<h3 className="mb-3">Alvic 🠖 Dyngas</h3>
				<div className="row justify-content-center">

					{ alvicAlerts > 0 &&
						<div className="col-md-12">
							<div className="row justify-content-center">
								<div className="col-md-8">
									<NavLink to="/alvic-to-dyngas" exact className="alert-wrapper">
					        			<div className="alert alert-danger">
					        				Se han encontrado {alvicAlerts} incidencias para revisar
					        			</div>
					        		</NavLink>
								</div>
							</div>
						</div>
					}

					<div className="col-md-4">
						<StatsBox
							title="PETICIONES"
							subtitle="RECIBIDAS"
							url="/alvic-logs/get-documents-resume/receptions"
						/>
					</div>

					<div className="col-md-4">
						<StatsBox
							title="PETICIONES"
							subtitle="ENVIADAS"
							url="/alvic-logs/get-documents-resume/sendings"
						/>
					</div>

				</div>
			</div>
			<div className="col-md-12 mb-4 text-center">
				<h3 className="mb-3">Dyngas 🠖 Alvic</h3>
				<div className="row justify-content-center">

					{ dyngasAlerts > 0 &&
						<div className="col-md-12">
							<div className="row justify-content-center">
								<div className="col-md-8">
					        		<NavLink to="/dyngas-to-alvic" exact className="alert-wrapper">
					        			<div className="alert alert-danger">
					        				Se han encontrado {dyngasAlerts} incidencias para revisar
					        			</div>
					        		</NavLink>
								</div>
							</div>
						</div>
					}

					<div className="col-md-4">
						<StatsBox
							title="PETICIONES"
							subtitle="RECIBIDAS"
							url="/dyngas-logs/get-documents-resume/receptions"
						/>
					</div>

					<div className="col-md-4">
						<StatsBox
							title="PETICIONES"
							subtitle="ENVIADAS"
							url="/dyngas-logs/get-documents-resume/sendings"
						/>
					</div>

				</div>
			</div>
		</AppLayout>
	);
}