import React, { useState, useRef, useEffect } from "react";
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const GlobalStyle = createGlobalStyle`
	.input {
		cursor: default;
		color: var(--bs-gray);
		white-space: nowrap;
		text-align: center;

		.fakeInput {
			display: inline-block;
			outline: none;
			color: #333;
			cursor: text;
			min-width: 10px;
		}
	}

	button {
		width: 40px;

		&:focus {
			box-shadow: none !important;
		}
	}
`;

export default function Paginator(props) {
	// State
	const [ min, setMin ] = useState(0);
	const [ max, setMax ] = useState(0);
	const [ current, setCurrent ] = useState(0);

	// Ref
	const inputRef = useRef(null);

	// Did mount
	useEffect(() => {
  		// Set data from props
  		setMin(props.min ?? 1);
  		setMax(props.max ?? 1);
  		setCurrent(props.current ?? 1);
  	}, [props]);

	function setPage(page) {
		page = parseInt(page);
		if ( page > max ) page = max;
		if ( page < min ) page = min;
		if ( isNaN(page) ) page = '';
		if ( page && page !== current ) props.changeCallback(page);
		setCurrent(page);
	}

	function addPage() {
		setPage(current+1);
	}

	function subPage() {
		setPage(current-1);
	}

	return (
		<React.Fragment>
			<GlobalStyle />
			<div id="paginator">
				<div className="input-group">
					<button className="btn btn-sm btn-plenoil-primary" onClick={(e) => subPage()}><FontAwesomeIcon icon={faMinus} size="xs" /></button>
					<div className="input form-control form-control-sm">
						<div 
							ref={inputRef} 
							className="fakeInput" 
							onBlur={(e) => setPage(e.target.innerHTML)}
							contentEditable 
							suppressContentEditableWarning={true}
						>{current}</div> / {max}
					</div>
					<button className="btn btn-sm btn-plenoil-primary" onClick={(e) => addPage()}><FontAwesomeIcon icon={faPlus} size="xs" /></button>
				</div>
			</div>
		</React.Fragment>
	);
}