import React, { useState, useRef, useEffect, useCallback } from "react";
import AppLayout from 'layouts/AppLayout';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
// import { Toast } from 'bootstrap';
import EditModal from "./EditModal";
import GlobalConfigModal from "./GlobalConfigModal";
import moment from "moment";

// Global styles
const GlobalStyle = createGlobalStyle`
	table {
		font-size: 13px;

		th {
			white-space: nowrap;

			&:nth-child(1) {
				width: 20px;
				
			}

			&:nth-child(2) {
			}

			&:nth-child(3) {
				width: 200px;
			}

			&:nth-child(4) {
				width: 200px;
			}

			&:nth-child(5) {
				text-align: center;
				width: 40px;
			}
		}

		td {

			&.warning {
				background: var(--plenoil-red);
				color: white;
			}
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;

export default function Stations() {
	const toastRef = useRef(null);

	const [stations, setStations] = useState([]);
	const [editModalOpened, setEditModalOpened] = useState(null);
	const [globalConfigModalOpened, setGlobalConfigModalOpened] = useState(null);
	const [toast, setToast] = useState(false);

	useEffect(() => {
		cancelTokenSource = axios.CancelToken.source();

  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, []);

  	// function showToast(msg) {
  	// 	setToast(msg);
  	// 	setTimeout(() => {
  	// 		setToast(false);
  	// 	}, 3000);
  	// }

  	const getData = useCallback(() => {
  		// Ajax
		axios.get('/stations-warnings/list', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setStations(response.data);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setStations([]);
	  	});		
  	}, []);

	const openEditModal = (e, station) => {
		e.preventDefault();
		
		setEditModalOpened(station);
	}

	useEffect(() => {
		getData();

		let interval = setInterval(() => {
			getData();
		}, 10000);
		
		return function cleanup() {
			clearInterval(interval);
		}
  	}, [getData]);

	return (
		<AppLayout>
			<GlobalStyle />

			<div className="col-md-12 mb-3">
				<button className="btn btn-secondary btn-sm p-1 float-end" onClick={() => setGlobalConfigModalOpened(true)}><FontAwesomeIcon icon={faPencilAlt} /> Configuración general</button>
				<h4 className="mb-0">Avisos</h4>
				<small>Listado y configuración de avisos</small>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Octan ID</th>
								<th>Nombre</th>
								<th>Recepción de Octan</th>
								<th>Envio a Business Central</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ stations && 	
								stations.map((el, idx) => {
									return (
										<tr key={idx}>
											<td>{el.octan_id}</td>
											<td>
												{el.name}
												<div style={{fontSize: '10px', lineHeight: '10px', color: 'gray'}}>
													{el.address} {el.postalcode} {el.province} {el.city}
												</div>
											</td>
											<td className={el.warning?.from_octan_warning_at ? 'warning' : ''}>
												{el.warning?.from_octan_last_date &&
													<>
													 	{moment(el.warning.from_octan_last_date).format('DD-MM-YYYY')}
														&nbsp;a las&nbsp;
													 	{moment(el.warning.from_octan_last_date).format('HH:mm')}
													</>
												}
											</td>
											<td className={el.warning?.to_bcentral_warning_at ? 'warning' : ''}>
												{el.warning?.to_bcentral_last_date &&
													<>
													 	{moment(el.warning.to_bcentral_last_date).format('DD-MM-YYYY')}
														&nbsp;a las&nbsp;
													 	{moment(el.warning.to_bcentral_last_date).format('HH:mm')}
													</>
												}
											</td>
											<td className="text-center">
												<a href="." className={"text-plenoil-primary"} onClick={(e) => openEditModal(e, el)}><FontAwesomeIcon icon={faPencilAlt} /></a>
											</td>
										</tr>
									);
								})
							}
							{ !stations.length && <tr><td colSpan="100%">No hay estaciones</td></tr> }
						</tbody>
					</table>
				</div>
			</div>

	        <div className="position-fixed bottom-0 end-0" style={{zIndex: 5000000}}>
		        <div className="toast float-end hide mb-2" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
					<div className="toast-header">
						<strong className="me-auto">Estaciones</strong>
						<small className="text-muted">ahora mismo</small>
						<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div className="toast-body">
						{toast}
					</div>
				</div>
			</div>

			{editModalOpened &&
				<EditModal 
					station={editModalOpened}
					closeCallback={() => {
						setEditModalOpened(null);
					}}
				/>
			}
			{globalConfigModalOpened &&
				<GlobalConfigModal 
					closeCallback={() => {
						setGlobalConfigModalOpened(null);
					}}
				/>
			}
		</AppLayout>
	);
}