import React, { useState, useContext, useRef } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from 'state';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import { assets } from 'helpers';

const GlobalStyle = createGlobalStyle`
	html, body {
		height: 100%;
		background-color: #182627;
	}

	#background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: url(${assets('/img/login-fondo.svg')});
		background-repeat: no-repeat;
		background-position: right;
		background-size: 100%;
		opacity: 0.1
	}

	#root,
	.container,
	.row {
		height: 100%;
	}

	.container {
		z-index: 2;
	}

	.logo {
		text-align: center;

		.img-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			img {

				&:first-of-type {
					width: 120px;
					height: 120px;
				}

				&:last-of-type {
					width: 240px;
				}
			}
		}

		.text {
			font-weight: bold;
			font-size: 40px;
			color: white;
		}
	}

	.card {
		border: none;
		background: transparent;

		.card-body {
			padding: 0px;
			overflow: hidden;
			border-radius: 25px;
			background: var(--plenoil-blue);
			border: 1px solid var(--plenoil-blue-light);

			.login-header {
				display: flex;
				justify-content: center;
				position: relative;
				background: url(${assets('/img/login-cabecera.png')});
				background-size: cover;
				height: 100px;

				h3 {
					position: absolute;
					color: var(--plenoil-yellow);
					text-align: center;
					margin: 0;
					padding: 0;
					font-size: 22px;
					font-weight: bold;
					top: 30px;
				}
			}

			.login-form {
				padding: 20px;

				.login-input {
					position: relative;
					border-bottom: 1px solid var(--plenoil-blue-light);
					margin-bottom: 15px;

					.login-input-img-wrapper {
						display: flex;
						align-items: center;
						position: absolute;
						width: 14px;
						height: 100%;

						.login-input-img {
							width: 100%;
							opacity: 0.3;
						}
					}
					
					input {
						color: white;
						border: none;
						background: transparent;
						width: 100%;
						font-size: 16px;
						letter-spacing: -0.5px;
						padding: 3px 10px;
						padding-left: 25px;

						&:focus {
							outline: none;
						}

						&::placeholder {
						  color: white;
						  opacity: 1;
						}

						&:-ms-input-placeholder {
						  color: white;
						}

						&::-ms-input-placeholder {
						  color: white;
						}
					}
				}

				button {
					background: var(--plenoil-orange);
					color: var(--plenoil-white);
					display: block;
					width: 100%;
					padding: 8px;
					border-radius: 30px;
					border: 0;
					margin-top: 20px;
					user-select: none;
					font-size: 15px;
				}

				.login-error {
					color: var(--plenoil-white);
					background: var(--plenoil-red);
					padding: 10px;
					border-radius: 30px;
					text-align: center;
					margin-top: 10px;
					font-size: 12px;
				}
			}

			label {
				color: var(--plenoil-yellow);
			}

			input {
				background: var(--plenoil-white);
			}
		}
	}
`;

export default function Login() {
	// State
	const [ loginError, setLoginError ] = useState(null);
	const { state, dispatch } = useContext(AppContext);

	// Refs
	const usernameRef = useRef(null);
	const passwordRef = useRef(null);

	function setUser(e) {
		e.preventDefault();

		// Remove error
		setLoginError(null);

		// Ajax
		axios.post('/get-token', {
		    username: usernameRef.current.value,
		    password: passwordRef.current.value
	  	})
	  	.then((response) => {
	  		// Set token
		    if ( response.data.token ) {
		    	dispatch({
					type: 'setToken', 
					data: response.data.token
				});
			}

			// Set user
		    if ( response.data.user ) {
		    	dispatch({
					type: 'setUser', 
					data: response.data.user
				});
		    }
	  	})
	  	.catch((error) => {
	  		// Set error
		    setLoginError('Datos de acceso no válidos');
	  	});		
	}

	// Check logged
	if ( state.user && state.token ) {
		return <Redirect to="/"></Redirect>;
	}

	// Render
	return (
		<React.Fragment>
			<div id="background"></div>
			<div className="container">
				<GlobalStyle />
				<div className="row justify-content-center align-items-center">
					<div className="col-md-4">
						<div className="logo">
							<div className="img-wrapper">
								<img src={assets("/img/isotype_white.png")} alt="Plenoil" />
								<img src={assets("/img/logo_white.png")} alt="Plenoil" />
							</div>
							<div className="text">
								MIDDLEWARE
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="login-header">
									<h3>USUARIOS</h3>
								</div>
								<div className="login-form">
									<form action="">
										<div className="login-input">
											<div className="login-input-img-wrapper">
												<img src={assets("/img/login-user.svg")} className="login-input-img" alt="NOMBRE" />
											</div>
											<input ref={usernameRef} placeholder="NOMBRE" type="text" />
										</div>
										<div className="login-input">
											<div className="login-input-img-wrapper">
												<img src={assets("/img/login-password.svg")} className="login-input-img" alt="CONTRASEÑA" />
											</div>
											<input ref={passwordRef} placeholder="CONTRASEÑA" type="password" />
										</div>
										<div className="d-flex flex-column d-justify-content-center">
											<button onClick={(e) => setUser(e)}>INICIAR SESIÓN</button>

											{ loginError &&
												<div className="login-error">{loginError}</div>
											}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
		</React.Fragment>
	);
}