import React, { useState, useRef, useEffect } from "react";
import AppLayout from 'layouts/AppLayout';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Toast } from 'bootstrap';

// Global styles
const GlobalStyle = createGlobalStyle`
	table {
		font-size: 13px;

		th {
			white-space: nowrap;

			&:nth-child(1) {
				
			}

			&:nth-child(2) {
				width: 20px;
			}

			&:nth-child(3) {
				width: 100px;
			}

			&:nth-child(4) {
				width: 100px;
			}

			&:nth-child(5) {
				width: 50px;
			}

			&:nth-child(6) {
				width: 120px;
			}

			&:nth-child(7) {
				width: 40px;
			}

			&:nth-child(8) {
				width: 40px;
			}
		}

		th,td {
			vertical-align: middle;

			&:nth-child(8) {
				width: 40px;
				white-space: nowrap;
			}
		}

		tr {
			td {
				&:nth-child(1) {
					white-space: nowrap !important;
				}
			}
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;

export default function Companies() {
	// State
	const [ companies, setCompanies ] = useState([]);
	const [ errors, setErrors ] = useState({});
	const [ toast, setToast ] = useState(false);

	// Ref
	const idRef = useRef(null);
	const nameRef = useRef(null);
	const codeRef = useRef(null);
	const urlRef = useRef(null);
	const userRef = useRef(null);
	const passwordRef = useRef(null);
	const toastRef = useRef(null);

	useEffect(() => {
		cancelTokenSource = axios.CancelToken.source();

  		getCompanies(); 

  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, []);

  	useEffect(() => {
        let bsToast = Toast.getInstance(toastRef.current);
        if ( !bsToast ) {
            // initialize Toast
            bsToast = new Toast(toastRef.current)
            // hide after init
            bsToast.hide();
            setToast(false);
        } else {
            // toggle
            toast ? bsToast.show() : bsToast.hide()
        }
  	}, [toast]);

  	function showToast(msg) {
  		setToast(msg);
  		setTimeout(() => {
  			setToast(false);
  		}, 3000);
  	}

  	function getCompanies() {
  		// Ajax
		axios.get('/companies/list', {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setCompanies(response.data);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setCompanies([]);
	  	});		
  	}

  	function getCompany(event, id) {
		event.preventDefault();

		// Remove errors
		setErrors({});

		// Ajax
		axios.get('/companies/get/' + id, {
		    cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Set data
	  		idRef.current.value = response.data.id;
	  		nameRef.current.value = response.data.name;
	  		codeRef.current.value = response.data.code;
	  		urlRef.current.value = response.data.url;
	  		userRef.current.value = response.data.user;
	  		passwordRef.current.value = response.data.password;
	  	});	
	}

	function save() {
		// Remove errors
		setErrors({});

		// Ajax
		axios.post('/companies/save', {
		    id: idRef.current.value,
		    name: nameRef.current.value,
		    code: codeRef.current.value,
		    url: urlRef.current.value,
		    user: userRef.current.value,
		    password: passwordRef.current.value,
	  	}, {
	  		cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
	  		// Reset form
	  		idRef.current.value = "";
	  		nameRef.current.value = "";
	  		codeRef.current.value = "";
	  		urlRef.current.value = "";
	  		userRef.current.value = "";
	  		passwordRef.current.value = "";

	  		// Reload
  			getCompanies(); 

  			// Toast
  			showToast('Se han guardado los datos');
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setErrors(error.response.data.errors);
	  	});		
	}

	function remove(e, id) {
		e.preventDefault();

		// Confirm
		if ( !window.confirm('¿Seguro que quieres eliminar esta empresa?') ) return;

		// Ajax
		axios.post('/companies/delete', {
		    id: id,
	  	}, {
	  		cancelToken: cancelTokenSource.token
	  	})
	  	.then((response) => {
  			// Toast
  			showToast('Se ha eliminado la empresa');
	  	})
	  	.catch((error) => {
	  		// Do nothing
	  	}).then((response) => {
	  		// Reload
  			getCompanies(); 
	  	})	
	}

	// Prepare
	let companiesRows = [];
	if ( companies ) companies.forEach((el, idx) => {
		companiesRows.push(
			<tr key={idx}>
				<td>{el.name}</td>
				<td>{el.code}</td>
				<td>{el.url}</td>
				<td>{el.user}</td>
				<td>{el.password}</td>
				<td className="text-center">
					<a href="." className={"text-plenoil-primary me-2"} onClick={(e) => getCompany(e, el.id)}><FontAwesomeIcon icon={faPencilAlt} /></a>
					<a href="." className="text-danger ms-1" onClick={(e) => remove(e, el.id)}><FontAwesomeIcon icon={faTimes} /></a>
				</td>
			</tr>
		);
	});
	if ( !companies.length ) companiesRows.push(<tr key="empty"><td colSpan="100%">No hay empresas</td></tr>);

	return (
		<AppLayout>
			<GlobalStyle />

			<div className="col-md-12 mb-3">
				<h4 className="mb-0">Listado de empresas</h4>
				<small>En este listado se encuentran los datos de acceso a los webservice de Business Central para cada empresa</small>
			</div>

			<div className="col-md-9 mb-3">
				<div className="table-responsive">
					<table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Nombre</th>
								<th>Código</th>
								<th>Url Webservice</th>
								<th>Usuario</th>
								<th>Contraseña</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ companiesRows }
						</tbody>
					</table>
				</div>
			</div>

			<div className="col-md-3 mb-3">
				<div className={'card'}>
					<div className="card-header">
						Añadir nueva empresa
					</div>
					<div className="card-body">
						<form id="form-add-edit">
							<input type="hidden" ref={idRef} />
		        			<div className={'mb-3 ' + (errors.name ? 'text-danger' : '')}>
		        				<label htmlFor="name">Nombre</label>
		        				<input ref={nameRef} type="text" id="name" className={'form-control form-control-sm ' + (errors.name ? 'border-danger' : '')} />
		        				{ 
		        					errors.name?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.code ? 'text-danger' : '')}>
		        				<label htmlFor="url">Código</label>
		        				<input ref={codeRef} type="text" id="url" className="form-control form-control-sm" />
		        				{ 
		        					errors.code?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.url ? 'text-danger' : '')}>
		        				<label htmlFor="url">Url Webservice</label>
		        				<input ref={urlRef} type="text" id="url" className="form-control form-control-sm" />
		        				{ 
		        					errors.url?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.user ? 'text-danger' : '')}>
		        				<label htmlFor="user">Usuario</label>
		        				<input ref={userRef} type="text" id="user" className="form-control form-control-sm" />
		        				{ 
		        					errors.user?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        			<div className={'mb-3 ' + (errors.password ? 'text-danger' : '')}>
		        				<label htmlFor="password">Contraseña</label>
		        				<input ref={passwordRef} type="text" id="password" className="form-control form-control-sm" />
		        				{ 
		        					errors.password?.map((el, idx) => {
		        						return <div key={idx}>· {el}</div>;
		        					})
		        				}
		        			</div>
		        		</form>
					</div>
					<div className="card-footer">
						<button type="submit" className="btn btn-sm btn-plenoil-orange float-end" onClick={() => save()}>Guardar</button>
					</div>
				</div>
			</div>

	        <div className="position-fixed bottom-0 end-0" style={{zIndex: 5000000}}>
		        <div className="toast float-end hide mb-2" role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
					<div className="toast-header">
						<strong className="me-auto">Empresas</strong>
						<small className="text-muted">ahora mismo</small>
						<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
					<div className="toast-body">
						{toast}
					</div>
				</div>
			</div>
		</AppLayout>
	);
}