import React, { createContext, useReducer } from "react";

export const AppContext = createContext();

const initialState = {
	api_url: (() => {
		let hostname = window.location.hostname;
		if ( hostname.indexOf('.local') !== -1 || hostname.indexOf('localhost') !== -1) hostname = 'plenoilmiddleware.local';
		return window.location.protocol + '//' + hostname + '/api';
	})(),
	user: (() => {
		try {
			return JSON.parse( localStorage.getItem('user') );
		} catch (err) {
			return null;
		}
	})(),
	token: localStorage.getItem('token'),
}

const reducer = (state, action) => {
	switch(action.type) {
		case "setToken": {
			localStorage.setItem('token', action.data ?? "");
		  	return {...state, token: action.data};
		}
		case "setUser": {
			localStorage.setItem('user', JSON.stringify(action.data ?? ""));
		  	return {...state, user: action.data};
		}
		default: break;
	}
	return state;
};

function AppContextProvider(props) {
	const fullInitialState = { ...initialState };

	let [state, dispatch] = useReducer(reducer, fullInitialState);
	let value = { state, dispatch };

	return (
		<AppContext.Provider value={value}>{props.children}</AppContext.Provider>
	);
}

export default AppContextProvider;