import Login from './pages/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Users from './pages/Users';
import Stations from './pages/Stations';
import StationsWarnings from './pages/StationsWarnings';
import Companies from './pages/Companies';
import AlvicToDyngasLog from './pages/AlvicToDyngasLog/AlvicToDyngasLog';
import DyngasToAlvicLog from './pages/DyngasToAlvicLog/DyngasToAlvicLog';

export const routes = [
	{
		path: '/',
		component: <Dashboard />,
		exact: true
	},
	{ 
		path: '/login', 
		component: <Login />
	},
	{ 
		path: '/users', 
		component: <Users />
	},
	{ 
		path: '/stations', 
		component: <Stations />
	},
	{ 
		path: '/stations-warnings', 
		component: <StationsWarnings />
	},
	{ 
		path: '/companies', 
		component: <Companies />
	},
	{ 
		path: '/alvic-to-dyngas', 
		component: <AlvicToDyngasLog />
	},
	{ 
		path: '/dyngas-to-alvic', 
		component: <DyngasToAlvicLog />
	}
];