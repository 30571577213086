import React, { useState, useEffect } from "react";
import { createGlobalStyle } from 'styled-components';
import axios from 'axios';

const GlobalStyle = createGlobalStyle`
	.card {
		background: transparent;
		overflow: hidden;

		.card-header {
			line-height: 20px;
			letter-spacing: -1px;
			padding: 10px;

			div {
				font-size: 25px;
			}
		}

		.card-body {
			background: white;
			letter-spacing: -1px;

			.total {
				margin-top: 0px;
				margin-bottom: 20px;
				font-size: 40px;
				line-height: 25px;
				font-weight: bold;
				letter-spacing: -3px;


				label {
					font-size: 15px;
					letter-spacing: -1px;

					display: block;
					text-align: center;
				}
			}	

			.documents-wrapper {
				display: flex;
				flex-wrap: wrap;

				.document {
					margin: 5px;
					padding: 5px;
					font-size: 25px;
					line-height: 15px;
					font-weight: bold;
					flex-grow: 1;
					background: var(--plenoil-blue-superlight);
					border-radius: 5px;
					letter-spacing: -2px;

					label {
						margin-bottom: 5px;
						font-size: 15px;
						letter-spacing: -1px;
						line-height: 15px;
						display: block;
						text-align: center;
					}
				}
			}		
		}

		.card-footer {
			background: transparent;
			display: flex;
			padding: 0;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-grow: 1;
				padding: 10px;
				font-size: 15px;
				line-height: 15px;
				color: #333;
				text-decoration: none;

				&:not(:last-of-type) {
					border-right: 1px solid lightgray;
				}

				&:hover {
					color: black;
				}

				&.active {
					background: var(--plenoil-blue-light);
					color: black;
				}
			}
		}
	}
`;

let cancelTokenSource = null;

export default function StatsBox(props) {
	// State
	const [ data, setData ] = useState({});
	const [ date, setDate ] = useState('today');

	// Did mount
	useEffect(() => {
		// Set axios cancel token
		cancelTokenSource = axios.CancelToken.source();

		// Get data
		const ajaxInterval = setInterval(() => {
			if ( date === "today" ) getData(props.url, date);
		}, 5000);
		getData(props.url, date);

		// Unmount callback
		return function cleanup() {
           	cancelTokenSource.cancel();
           	clearInterval(ajaxInterval);
        }
	}, [props.url, date]);

	function getData(url, date) {
		if ( !url ) return;

		// Ajax
		axios.get(url, {
			params: {
				date: date 
			},
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setData({...response.data});
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setData({});
	  	});
	}

	function onClickSetDate(e, date) {
		e.preventDefault();

		setDate(date);
	}
 
	// Prepare documents
	let documents = [];
	if ( data.documents ) for(let idx in data.documents) {
		let qty = data.documents[idx];

		documents.push(
			<div key={idx} className="document">
				<label>{idx}</label>
				{qty}
			</div>
		);
	}

	// Render
	return (
		<React.Fragment>
			<GlobalStyle />
			<div className="card">
				<div className="card-header">
					{props.title ?? ''}
					<div>{props.subtitle ?? ''}</div>
				</div>
				<div className="card-body">
					<div className="total">
						<label>TOTAL</label>
						{data.total ?? 0}
					</div>
					<div className="documents-wrapper">
						{documents}
					</div>
				</div>
				<div className="card-footer">
					<a href="." className={date === 'today' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'today')}>Hoy</a>
					<a href="." className={date === 'yesterday' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'yesterday')}>Ayer</a>
					<a href="." className={date === 'thismonth' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'thismonth')}>Este mes</a>
					<a href="." className={date === 'lastmonth' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'lastmonth')}>Mes pasado</a>
					{/* <a href="." className={date === 'thisyear' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'thisyear')}>Este año</a> */}
					{/* <a href="." className={date === 'lastyear' ? 'active' : ''} onClick={(e) => onClickSetDate(e, 'lastyear')}>Año pasado</a> */}
				</div>
			</div>
		</React.Fragment>
	);
}