import React, { useState, useEffect } from "react";
import { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { loader } from 'helpers';

// Global Style
const GlobalStyle = createGlobalStyle`
	#loader {
		width: 50px;
		display: block;
		margin: 0 auto;
	}

	table#table-details {
		margin-bottom: 0px;
		font-size: 13px;

		thead {

			th {
				border-bottom: 0;
				
				&:nth-child(1) {
					width: 100px;
				}
			}
		}

		tbody {

			tr {

				&:last-of-type {

					td {
						border-bottom: 0;
					}
				}

				td {

					.badge {
						font-size: 13px;
					}

					&:nth-child(2) {
						width: 100px;
					}

					&:nth-child(3) {
						max-width: 200px;

						.hljs {
							overflow: hidden !important;
						}

						pre.pre-errors,
						.highlight-wrapper {
							display: block;
							width: 100%;
							max-height: 150px;
							overflow: auto;
							border: 1px solid var(--plenoil-blue-light);
							background: white;
						}
					}

					&:last-of-type {
						width: 100px;
					}
				}
			}
		}

		td, th {
			cursor: auto;
		}
	}
`;

// Axios cancel token
let cancelTokenSource = null;
 
export default function DetailsRow(props) {
	// State
	const [ data, setData ] = useState([]);
	const [ loading, setLoading ] = useState(false);

  	function getData(id) {
  		setLoading(true);

		axios.get('/dyngas-logs/get-reception/' + id, {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		setData({...response.data});
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setData([]);
	  	}).then(() => {
	  		setLoading(false);
	  	});		
  	}

  	function addLog(event, reception_id, type) {
  		event.preventDefault();

  		axios.post('/dyngas-logs/'+type+'-reception', {
  			id: reception_id
  		}, {
			cancelToken: cancelTokenSource.token
		})
	  	.then((response) => {
	  		getData(reception_id);
	  	})
	  	.catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  	});	
  	}

  	// Did mount
	useEffect(() => {
		cancelTokenSource = axios.CancelToken.source();
  		
  		getData(props.id); 

  		return function cleanup() {
           	cancelTokenSource.cancel();
        }
  	}, [props.id]);

  	let rows = [];
  	if ( data.reception && data.reception.log ) {
  		let log = [...data.reception.log].reverse();
  		log.forEach((el, idx) => {
  			// Prepare actions
  			let actions = [];
  			if ( idx === 0 ) {
	  			if ( el.status === "sender-retry" ) {
	  				actions.push(<a key="retry" href="." className="btn btn-sm btn-danger mb-2" onClick={(e) => addLog(e, props.id, 'cancel')}>Cancelar</a>);
	  			}

	  			if ( el.status === "sender-retry" || el.status === "sender-error" || el.status === "converter-error" ) {
	  				actions.push(<a key="ignore" href="." className="btn btn-sm btn-secondary mb-2"  onClick={(e) => addLog(e, props.id, 'ignore')}>Ignorar</a>);
	  			}	
  			}

  			// Push
	  		rows.push(
	  			<tr key={idx}>
	  				<td>
	  					<div>{moment(el.date).format('DD-MM-YYYY')}</div>
						<small>{moment(el.date).format('HH:mm:ss')}</small>
	  				</td>
	  				<td>
  						<span className={"badge bg-" + (props.logStatuses[el.status]?.color)}>{props.logStatuses[el.status]?.name}</span>
	  				</td>
	  				<td>
	  					{dyngas_to_octan_details_generator(el, data.reception)}
	  				</td>
	  				<td className="text-center">
	  					{actions}
	  				</td>
	  			</tr>
	  		);
  		});
	  }

  	// Render
	return (
		<React.Fragment>
			<GlobalStyle />
			{ loading ? loader() : '' }
			{ rows.length > 0 &&
				<table className="table table-sm" id="table-details">
					<thead>
						<tr>
							<th>Momento</th>
							<th>Tarea</th>
							<th>Detalles</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
			}
		</React.Fragment>
	);
}














function dyngas_to_octan_details_generator(data, reception) {
	if ( data.status === "data-received" ) {
		if ( reception ) {
			return (
				<div className="highlight-wrapper">
					<pre>
						<code>
							{JSON.stringify(reception.data, null, 2)}
						</code>
					</pre>
				</div>
			);
		}
	}

	if ( data.status === "sender-init" ) {
		if ( data.payload.attempt !== undefined ) return <div>Intento: {data.payload.attempt}</div>
	}

	if ( data.status === "sender-retry" ) {
		if ( data.payload.time !== undefined ) return <div>Nuevo intento en: {data.payload.time}</div>
	}

	if ( data.status === "sender-error" ) {
		return (
			<React.Fragment>
				<div>{data.payload.message}</div>
				<div>
					<pre className="pre-errors">
						{data.payload.stacktrace}
					</pre>
				</div>
			</React.Fragment>
		);
	}
}